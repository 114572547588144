import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useNavigate } from 'react-router';
import images from '../../assets/images';
import {
  permissionState,
  userPermissionState,
  userState,
} from '../../shared/store/slices/userSlice';
import {
  getFullname,
  getInitials,
  isAllowedTo,
  sortListAcc,
} from '../../shared/util/util';
import RemoveMemberFromTheSpaceDialog from '../../components/dialog/member-detail-dialog/RemoveMemberFromSpace';
import { currentSpaceState } from '../../shared/store/slices/companySlice';

function SpaceTeamListTable({ teamList, spaceOwner, loading, getTeamList }) {
  const navigate = useNavigate();

  const userSel = useSelector(userState);
  const currentSpace = useSelector(currentSpaceState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);

  const [sortAsc, setSortAsc] = useState(false);
  const [spaceTeamList, setSpaceTeamList] = useState(teamList ?? []);
  const [anchorSl, setAnchorSl] = useState(null);
  const [RemoveMemberFromSpace, setRemoveMemberFromSpace] = useState(false);
  const [userDetail, setUserDetail] = useState(null);

  useEffect(() => {
    setSpaceTeamList(teamList);
  }, [teamList.length]);

  const handleClick2 = (idx, event) => {
    setAnchorSl({ [idx]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorSl(null);
    setUserDetail(null);
    setRemoveMemberFromSpace(false);
  };

  const navigateToProject = (spaceId, projectId) => {
    navigate(`/company/spaces/${spaceId}/project/${projectId}/overview`);
  };

  const toggleSort = () => {
    setSortAsc(!sortAsc);
    let sortedList = sortListAcc(teamList, 'first_name', sortAsc);
    setSpaceTeamList(sortedList);
  };

  const RemoveMemberFromSpaceDialogClose = (e, userRemoved = false) => {
    if (userRemoved) {
      getTeamList();
    }
    setRemoveMemberFromSpace(false);
  };

  const openRemoveMemberDialog = (user = null) => {
    setUserDetail(user);
    setRemoveMemberFromSpace(true);
  };

  const isAllowedToRemoveSpaceMember = () =>
    isAllowedTo(
      'destroy',
      'space_member',
      permissions,
      { id: currentSpace?.id, type: 'Space' },
      userPermissions
    );

  //=====================================================

  return (
    <>
      <TableContainer className="tabListView members-spaces-sec">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Name{' '}
                <em className="cursor-pointer" onClick={toggleSort}>
                  {sortAsc ? (
                    <span className="icon be-left-arrow move-up"></span>
                  ) : (
                    <span className="icon be-left-arrow move-down"></span>
                  )}
                </em>
              </TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Buildcard Lead</TableCell>
              {isAllowedToRemoveSpaceMember() && <TableCell>&nbsp;</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && spaceOwner && Object.keys(spaceOwner).length > 0 && (
              <TableRow>
                <TableCell className="text-nowrap">
                  <div className="user-link">
                    {/* if user image not available */}
                    {/* <span className='initials'>
                                AC
                            </span> */}
                    <span className="user-avatar">
                      {spaceOwner?.profile_picture?.profile_picture_url ? (
                        <img
                          src={spaceOwner?.profile_picture?.profile_picture_url}
                          alt="profile url"
                        />
                      ) : (
                        getInitials(
                          getFullname(
                            spaceOwner?.first_name,
                            spaceOwner?.last_name
                          )
                        )
                      )}
                    </span>
                    <span className="user-name">
                      {getFullname(
                        spaceOwner?.first_name,
                        spaceOwner?.last_name
                      ) ?? '-'}
                    </span>
                    &nbsp;{userSel?.id == spaceOwner?.id && <span>(You)</span>}
                  </div>
                </TableCell>
                <TableCell className="text-nowrap">
                  {spaceOwner?.email}
                </TableCell>
                <TableCell className="text-nowrap">Space Owner</TableCell>
                <TableCell className="text-nowrap">
                  {spaceOwner?.build_card_owner ? 'Yes' : 'No'}
                  {spaceOwner?.owned_build_cards?.length > 0 && (
                    <Tooltip
                      placement="bottom-end"
                      enterTouchDelay={0}
                      classes={{
                        popper:
                          'info-tooltip capt-first-letter stack-menu list-buildcards',
                      }}
                      title={
                        <Stack
                          direction="row"
                          className="purple-chips"
                          spacing={1}
                        >
                          {spaceOwner?.owned_build_cards?.map((item) =>
                            item?.name ? (
                              <Chip key={item?.id} label={item?.name} />
                            ) : (
                              <></>
                            )
                          )}
                        </Stack>
                      }
                    >
                      <em className="icon be-info"></em>
                    </Tooltip>
                  )}
                </TableCell>
                {isAllowedToRemoveSpaceMember() && (
                  <TableCell className="text-nowrap">
                    <div className="three-dots">
                      <em
                        className="icon be-vertical-dots"
                        onClick={(e) => handleClick2(-1, e)}
                      ></em>
                      <Menu
                        id="basic-menu"
                        className={'three-dot-menu-paper'}
                        anchorEl={anchorSl && anchorSl[-1]}
                        open={Boolean(anchorSl && anchorSl[-1])}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem
                          data-testid="menu-rename"
                          onClick={(e) => {
                            openRemoveMemberDialog(spaceOwner);
                            setAnchorSl(null);
                          }}
                        >
                          Remove member
                        </MenuItem>
                      </Menu>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            )}
            {!loading &&
              spaceTeamList?.map((team, index) => {
                return (
                  <TableRow key={team?.id}>
                    <TableCell className="text-nowrap">
                      <div className="user-link">
                        {/* if user image not available */}
                        {/* <span className='initials'>
                                        AC
                                    </span> */}
                        <span className="user-avatar">
                          {team?.profile_picture?.profile_picture_url ? (
                            <img
                              src={team?.profile_picture?.profile_picture_url}
                              alt=""
                            />
                          ) : (
                            (getInitials(
                              getFullname(team?.first_name, team.last_name)
                            ) ?? <em className="icon be-user"></em>)
                          )}
                        </span>
                        <span className="user-name">
                          {getFullname(team?.first_name, team.last_name) ?? '-'}
                        </span>
                        &nbsp;
                      </div>
                    </TableCell>
                    <TableCell className="text-nowrap">{team?.email}</TableCell>
                    <TableCell className="text-nowrap">Member</TableCell>
                    <TableCell className="text-nowrap">
                      {team?.build_card_owner ? 'Yes' : 'No'}
                      {team?.owned_build_cards?.length > 0 && (
                        <Tooltip
                          placement="bottom-end"
                          enterTouchDelay={0}
                          classes={{
                            popper:
                              'info-tooltip capt-first-letter stack-menu list-buildcards',
                          }}
                          title={
                            <Stack
                              direction="row"
                              className="purple-chips"
                              spacing={1}
                            >
                              {team?.owned_build_cards?.map((item) =>
                                item?.name ? (
                                  <Chip key={item?.id} label={item?.name} />
                                ) : (
                                  <></>
                                )
                              )}
                            </Stack>
                          }
                        >
                          <em className="icon be-info"></em>
                        </Tooltip>
                      )}
                    </TableCell>
                    {isAllowedToRemoveSpaceMember() && (
                      <TableCell className="text-nowrap">
                        <div className="three-dots">
                          <em
                            className="icon be-vertical-dots"
                            onClick={(e) => handleClick2(index, e)}
                          ></em>
                          <Menu
                            id="basic-menu"
                            className={'three-dot-menu-paper'}
                            anchorEl={anchorSl && anchorSl[index]}
                            open={Boolean(anchorSl && anchorSl[index])}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem
                              data-testid="menu-rename"
                              onClick={() => {
                                openRemoveMemberDialog(team);
                                setAnchorSl(null);
                              }}
                            >
                              Remove member
                            </MenuItem>
                          </Menu>
                        </div>
                      </TableCell>
                    )}

                    {/* <TableCell className='text-nowrap'>
                                    { team?.project_lead && team?.owned_projects?.length > 0 && <div className='three-dots'>
                                        <em className='icon be-vertical-dots' onClick={(e) => handleClick2(index, e)}></em>
                                        <Menu
                                            id="basic-menu"
                                            className='three-dot-menu'
                                            anchorEl={anchorSl && anchorSl[index]}
                                            open={Boolean(anchorSl && anchorSl[index])}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                            }}
                                            MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            {team?.owned_projects?.map( item => <MenuItem key={item?.id} className='toggle-menu-item-row' onClick={handleClose}>
                                                <Button onClick={() => navigateToProject(item?.space_id, item?.id)} data-testid="add-space-to-list" variant="contained" className="xs-btn purple-btn">{ item?.name }</Button>
                                            </MenuItem>)}
                                        </Menu>
                                    </div>}
                                </TableCell> */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <RemoveMemberFromTheSpaceDialog
        {...{
          RemoveMemberFromSpace,
          RemoveMemberFromSpaceDialogClose,
          currentSpace,
          userDetail,
        }}
      />
    </>
  );
}

export default memo(SpaceTeamListTable);
