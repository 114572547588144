import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import instance from '../../../shared/interceptor';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import { formatDate, globalError, setLoader } from '../../../shared/util/util';

function RequestCreditDialog({
  openRequestCreditDialogue,
  closeRequestCreditDialogue,
  handleClickOpen,
}) {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);

  const [formVal, setFormVal] = useState({
    credit_requested: '',
    request_note: '',
  });

  const createSpaceCreditRequest = async () => {
    try {
      let note = formVal?.request_note?.trim();
      if (!note) {
        return globalError(dispatch, { message: 'Note cannot be blank' });
      }
      if (note?.length > 100) {
        return globalError(dispatch, {
          message: 'Note length should not be greater than 100 characters',
        });
      }
      setLoader(dispatch, true);
      const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${currentSpaceSel?.active_space_credit_id}/space_credit_requests`;
      let payload = {
        space_credit_request: formVal,
      };
      await instance.post(url, payload);
      closeRequestCreditDialogue(true);
      dispatch(setSuccessMsg('Request submitted successfully.'));
    } catch (err) {
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    closeRequestCreditDialogue(null);
  };

  const handleBtnClick = () => {
    closeRequestCreditDialogue();
    if (openRequestCreditDialogue?.request_history) handleClickOpen();
  };

  //========================================================
  return (
    <Dialog
      open={Boolean(openRequestCreditDialogue)}
      onClose={handleModalClose}
      className="request-credit-modal"
      data-testid="request-credit-modal"
    >
      <DialogContent aria-label="req-credit-modal" className="modal-body-wrap">
        {openRequestCreditDialogue?.pending_space_credit_request
          ?.credit_requested > 0 && (
          <span
            className="modal-close be-close"
            onClick={() => closeRequestCreditDialogue(null)}
          ></span>
        )}
        <div className="modal-body">
          <div className="modal-title">
            <h2>Request extra credits?</h2>
            <p></p>
          </div>
          <div className="modal-form">
            {openRequestCreditDialogue?.pending_space_credit_request
              ?.credit_requested > 0 && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <div className="credit-note" data-testid="pending-req-note">
                      <p>
                        Your request for{' '}
                        <strong>
                          {
                            openRequestCreditDialogue
                              ?.pending_space_credit_request?.credit_requested
                          }
                        </strong>{' '}
                        credits is still pending from{' '}
                        <strong>
                          {formatDate(
                            openRequestCreditDialogue
                              ?.pending_space_credit_request?.created_at
                          )}
                        </strong>
                        .
                      </p>
                      <p>
                        Do you want to request more credit? If you do, it’ll be
                        added as a top-up to your previous request and we’ll
                        send a note with both to be approved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormLabel>Credits</FormLabel>
                  <FormControl>
                    <InputLabel>Enter credits</InputLabel>
                    <FilledInput
                      onKeyDown={(evt) =>
                        ['e', 'E', '.', '-', '+'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={formVal?.credit_requested}
                      type="number"
                      placeholder=""
                      onChange={(e) =>
                        setFormVal({
                          ...formVal,
                          credit_requested: e?.target?.value,
                        })
                      }
                    />
                  </FormControl>
                  {+formVal?.credit_requested >
                    +compSel?.active_plan_credit_subscription
                      ?.credit_balance && (
                    <span className="form-error">
                      Cannot allocate more than the credit balance
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormLabel>Request note</FormLabel>
                  <FormControl>
                    <textarea
                      value={formVal?.request_note}
                      onChange={(e) =>
                        setFormVal({
                          ...formVal,
                          request_note: e?.target?.value,
                        })
                      }
                      className="custom-textarea2"
                      rows={4}
                      placeholder="Explain why you need these extra credits — this will help your approver to decide."
                    ></textarea>
                  </FormControl>
                  <span className="form-error"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          {openRequestCreditDialogue?.pending_space_credit_request
            ?.credit_requested > 0 ? (
            <Button
              variant="contained"
              className="grey-btn sm-btn"
              onClick={() => {
                closeRequestCreditDialogue();
                handleClickOpen();
              }}
            >
              Credit request history
            </Button>
          ) : (
            <Button
              variant="contained"
              className="grey-btn sm-btn"
              onClick={() => closeRequestCreditDialogue(null)}
            >
              Cancel
            </Button>
          )}
          {/* <Button data-testid='req-credit-btn' variant="contained" className="sm-btn green-btn" disabled={ !formVal?.credit_requested || !formVal?.request_note || formVal?.credit_requested > openRequestCreditDialogue?.credit_balance} onClick={createSpaceCreditRequest}>Request credits</Button> */}
          <Button
            data-testid="req-credit-btn"
            variant="contained"
            className="sm-btn green-btn"
            disabled={
              !formVal?.credit_requested ||
              !formVal?.request_note ||
              +formVal?.credit_requested >
                +compSel?.active_plan_credit_subscription?.credit_balance
            }
            onClick={createSpaceCreditRequest}
          >
            Request credits
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default RequestCreditDialog;
