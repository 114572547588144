import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { startTransition, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { navigateAccordingToRoleAndPaymentStatus } from '../util/util';
import { signInAs } from '../../pages/demo-purpose/DemoAuthProcess';
import { companyState, userState } from '../store/slices/userSlice';
function RightPageHeader() {
  const compSel = useSelector(companyState);
  const userSel = useSelector(userState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [SpaceClassToggle, setSpaceClassToggle] = useState(false);
  const [CompanyClassToggle, setCompanyClassToggle] = useState(false);
  let loggedInUser =
    ['member', 'space admin'].includes(userSel?.role?.name?.toLowerCase()) &&
    userSel?.space_owner
      ? 'space manager'
      : userSel?.role?.name?.toLowerCase();
  const [selectedUser, setSelectedUser] = useState(loggedInUser);

  const changeUserLogin = (role) => {
    let from = window.location.pathname;
    let userSelectedRole = role?.role_name?.toLowerCase();
    setSelectedUser(userSelectedRole);
    if (from.includes('owner-overview') || from.includes('dashboard')) {
      from = '';
    }
    signInAs(
      userSelectedRole,
      compSel,
      dispatch,
      startTransition,
      navigate,
      from
    );
  };

  const toggleBodyClass = () => {
    document.body.classList.toggle('sidenav-visible');
  };

  return (
    <div
      className="right-page-header"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <div className="mob-header">
        <div className="hamburger-icon" onClick={toggleBodyClass}>
          <div className="hamburger">
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
          </div>
        </div>
        <a
          href={void 0}
          onClick={() => {
            navigateAccordingToRoleAndPaymentStatus(
              startTransition,
              navigate,
              compSel,
              userSel
            );
            setSpaceClassToggle(false);
            setCompanyClassToggle(false);
          }}
          className="site-logo be-builder-logo-b"
        ></a>
      </div>
      {compSel?.demo_company_users?.length > 0 && (
        <span>
          <div className="cio-login">
            <label>Logged in as </label>
            <Select
              className="borderless-dropdown sm-height no-label"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedUser || loggedInUser}
            >
              {compSel?.demo_company_users?.map((role) => (
                <MenuItem
                  onClick={() => changeUserLogin(role)}
                  key={role.id}
                  value={role?.role_name?.toLowerCase()}
                >
                  {role.role_name}{' '}
                </MenuItem>
              ))}
            </Select>
          </div>
        </span>
      )}

      <div className="compnay-logo" style={{ position: 'relative' }}>
        {compSel?.logo?.logo_url && (
          <img src={compSel?.logo?.logo_url} alt="company logo" />
        )}
      </div>
    </div>
  );
}

export default RightPageHeader;
