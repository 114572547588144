import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FilledInput from '@mui/material/FilledInput';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
} from '@mui/material';
import { error } from 'console';
import {
  MAX_DESCRIPTION_LENGTH,
  MAX_NAME_LENGTH,
} from '../../../shared/util/constant';
import images from '../../../assets/images';

function CreateTemplateDialog({ createTemplate, closeCreateTemplateDialog }) {
  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    createTemplate(e);
  };

  function setIsDragOver(arg0: boolean): void {
    throw new Error('Function not implemented.');
  }

  function handleForce(files: FileList) {
    throw new Error('Function not implemented.');
  }

  //============================================================================

  return (
    <Dialog open={createTemplate} onClose={handleModalClose}>
      <DialogContent className="">
        {/* <span
          className="modal-close be-close"
          onClick={closeEditTemplateDialog}
        ></span> */}
        <div
          aria-label="delete-modal"
          className="modal-body edit-template-dialog"
        >
          <div className="">
            <h2>Create template</h2>
          </div>
          <p>
            Templates let you reuse common journeys and features. You can use
            this template to create new buildcards faster in Studio.
          </p>
          <div className="form-group">
            <div className="form-group">
              <FormControl variant="filled">
                <InputLabel>Template name</InputLabel>
                {/* @ts-ignore */}
                <FilledInput
                  inputProps={{ 'data-testid': 'first_name' }}
                  type="text"
                  defaultValue="Template of Warehouse scanner 1.0"
                  name="first_name"
                />
              </FormControl>
            </div>
          </div>

          <div className="form-group textarea-box">
            {/* <div className="num">56/160</div> */}
            <TextField
              id="outlined-basic"
              type="text"
              label="Describe what this template is about"
              multiline
              rows={4}
              variant="outlined"
              name="Description"
              // value="I did some more QA and the dropdown should also close when I click on the entire button, and not only on the chevron icon."
              className="custom-textarea top-spacing"
            />

            {/* <span className="form-error">sdsd</span> */}
          </div>
          <div className="form-group">
            <label>
              Upload thumbnail (optional){' '}
              <a
                href=""
                style={{
                  fontWeight: '500',
                  position: 'relative',
                  top: '0.4rem',
                }}
                className="d-flex align-items-center"
              >
                <em className="icon be-refresh"></em>Reset to default
              </a>
            </label>
            <div className="d-flex align-items-center">
              <em>
                <img src={images.catalogSw} alt="catalog icon" />
              </em>
              <span className="or">OR</span>
              <div
                data-testid="drop-area"
                className={` ${setIsDragOver ? 'file-uploading' : ''}`}
                onDragLeave={() => setIsDragOver(false)}
                onDragOver={(e) => {
                  e.preventDefault();
                  setIsDragOver(true);
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  handleForce(e.dataTransfer.files);
                }}
              >
                <div className="d-flex justify-content-center">
                  <em className="thumb">
                    <img src={images.cloudUpload} alt="upload" />
                  </em>
                  <span>Drop image or</span>
                  <a href={void 0} className="browse-files-link example-csv">
                    <input
                      type="file"
                      className="hidden-input"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => {
                        e.preventDefault();
                        handleForce(e.target.files);
                      }}
                    />
                    browse file
                  </a>
                </div>
                <p>Dimesion 56 x 56 | Max size 1 Mb</p>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            aria-label="close-delete-dialog"
            variant="contained"
            className="sm-btn grey-btn"
            onClick={closeCreateTemplateDialog}
          >
            Cancel
          </Button>
          <Button variant="contained" className="green-btn sm-btn">
            Save changes
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default CreateTemplateDialog;
function closeEditTemplateDialog(e: any) {
  throw new Error('Function not implemented.');
}
