import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import images from '../../../assets/images';
import instance from '../../../shared/interceptor';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { SPACES } from '../../../shared/util/constant';
import { debounce, globalError, setLoader } from '../../../shared/util/util';

function AddBuildcardDialog({
  openDialogue,
  handleClose,
  spaceGoalBuildcardList,
}) {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const params = useParams();

  const [buildcardList, setBuildcardList] = useState([]);
  const [buildcardSearchList, setBuildcardSearchList] = useState([]);
  const [selectedBc, setSelectedBc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const tagBuildcard = async () => {
    try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals/${params?.space_goal_id}/tag_build_cards`;
      const payload = {
        build_card_ids: selectedBc.map((item) => item?.enterprise_id),
      };
      await instance.post(url, payload);
      dispatch(setSuccessMsg('Space goal added to Buildcard successfully.'));
      handleClose(null, true);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const getListOfBuildcards = debounce(async (query) => {
    try {
      setLoading(true);
      let url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/list_buildcards`;
      if (query) {
        url += `?q=${query}`;
      }
      let res = await instance.get(url);
      setBuildcardList(res['build_cards']);
      setBuildcardSearchList(res['build_cards']);
      res?.['build_cards']?.length ? setOpen(true) : setOpen(false);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
    }
  });

  const filterBuildcards = (value) => {
    if (!value) {
      setBuildcardList(buildcardSearchList);
      buildcardSearchList?.length ? setOpen(true) : setOpen(false);
      return;
    }
    setBuildcardList(
      buildcardSearchList?.filter((item) =>
        (item?.alias_name ?? item?.name)
          ?.toLowerCase()
          ?.includes(value?.toLowerCase())
      )
    );
    buildcardSearchList?.filter((item) =>
      (item?.alias_name ?? item?.name)
        ?.toLowerCase()
        ?.includes(value?.toLowerCase())
    )?.length
      ? setOpen(true)
      : setOpen(false);
  };

  const addToSelected = (value) => {
    let val = [...new Map(value.map((item) => [item['id'], item])).values()];
    setSelectedBc(val);
  };

  const handleDelete = (idx) => {
    let tempArr = [...selectedBc];
    tempArr.splice(idx, 1);
    setSelectedBc(tempArr);
  };

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    handleClose(e, false);
  };

  //=========================================

  return (
    <Dialog
      open={!!openDialogue}
      onClose={handleModalClose}
      className="business-goal-modal"
      data-testid="add-buildcard-dialog"
    >
      <DialogContent className="business-goal-body-wrap">
        <div className="modal-body">
          <div className="modal-title mb-24">
            <h2>{openDialogue?.name}</h2>
          </div>
          <div className="modal-form empty-spacing">
            <div className="row">
              <div className="col-sm-12">
                <h6>Add Buildcard</h6>
                <div className="form-group searchbar-wrap">
                  <FormControl>
                    <Autocomplete
                      data-testid="autocomplete"
                      loading={loading}
                      noOptionsText={loading ? 'Loading' : null}
                      getOptionDisabled={(option) =>
                        !!spaceGoalBuildcardList.find(
                          (element) => element.id == option.id
                        )
                      }
                      id=""
                      multiple
                      disableClearable
                      disableCloseOnSelect
                      open={open}
                      onBlur={() => setOpen(false)}
                      filterOptions={(options) => options}
                      isOptionEqualToValue={(option, value) =>
                        option.id == value.id
                      }
                      value={selectedBc}
                      options={buildcardList}
                      autoHighlight
                      renderTags={(value: string[], getTagProps) => ''}
                      onChange={(event, value) => addToSelected(value)}
                      classes={{ popper: 'search-dropdown-popper' }}
                      getOptionLabel={(option) =>
                        option?.alias_name ?? option?.name
                      }
                      renderOption={(props, option, { selected }) => (
                        <Box
                          data-testid="bc-options"
                          component="span"
                          {...props}
                          key={option?.id}
                        >
                          <div className="search-goals-dropdown">
                            <div className="left-side">
                              <span className="user-description">
                                <span className="name">
                                  {option?.name || ''}
                                </span>
                              </span>
                            </div>
                            <div className="right-side">
                              <Checkbox
                                className="square-checkbox"
                                checked={
                                  selected ||
                                  !!spaceGoalBuildcardList.find(
                                    (element) => element.id == option.id
                                  )
                                }
                                icon={
                                  <img src={images.checkboxUnchecked} alt="" />
                                }
                                checkedIcon={
                                  <img src={images.checkboxChecked} alt="" />
                                }
                              />
                            </div>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          label={
                            selectedBc?.length > 0
                              ? `${selectedBc.length} selected`
                              : 'Select Buildcard'
                          }
                          {...params}
                          onClick={(e) =>
                            getListOfBuildcards((e?.target as any)?.value)
                          }
                          onChange={(e) => filterBuildcards(e?.target?.value)}
                          className="search-input-box"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="chips-wrapper list-items">
                    {/* <Stack direction="row" spacing={1}>
                      {selectedBc.length > 0 && selectedBc.map((item, idx) =>
                        <Chip key={item?.id} label={item?.name} deleteIcon={<span className='icon be-close-dark'></span>} onDelete={() => handleDelete(idx)} />
                      )}
                    </Stack> */}
                    <ul>
                      {selectedBc.length > 0 &&
                        selectedBc.map((item, idx) => (
                          <li key={item?.id}>
                            <span>{item?.name}</span>{' '}
                            <em
                              className="icon be-close"
                              onClick={() => handleDelete(idx)}
                            ></em>
                          </li>
                        ))}
                    </ul>
                  </div>
                  {!selectedBc.length && (
                    <div className="has-right-data checkbox-container">
                      <span>No Buildcard selected yet. </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            variant="contained"
            className="sm-btn grey-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="add-tag-btn"
            variant="contained"
            className="sm-btn green-btn"
            disabled={!selectedBc.length}
            onClick={tagBuildcard}
          >
            Add
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default AddBuildcardDialog;
