import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

function DeleteCatalogueDialog({
  deleteCatalogue,
  closeDeleteCatalogueDialog,
}) {
  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    closeDeleteDialog(e);
  };

  //============================================================================

  return (
    <Dialog open={deleteCatalogue} onClose={handleModalClose}>
      <DialogContent className="">
        {/* <span
          className="modal-close be-close"
          onClick={closeDeleteCatalogueDialog}
        ></span> */}
        <div aria-label="delete-modal" className="modal-body">
          <div className="">
            <h2>Are you sure you want to remove this template?</h2>
          </div>
          <p>
            This template will be permanently deleted and can no longer be used
            to create Buildcards. This action can’t be undone.
          </p>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            aria-label="close-delete-dialog"
            variant="contained"
            className="sm-btn grey-btn"
            onClick={closeDeleteCatalogueDialog}
          >
            Cancel
          </Button>
          <Button variant="contained" className="red-btn sm-btn">
            Remove
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteCatalogueDialog;
function closeDeleteDialog(e: any) {
  throw new Error('Function not implemented.');
}
