import React, { useState } from 'react';
import RightPageHeader from '../../../shared/shared-comp/RightPageHeader';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FilledInput,
  InputAdornment,
  MenuItem,
  Select,
  Tab,
  Menu,
  Pagination,
} from '@mui/material';
import images from '../../../assets/images';
import { NavLink } from 'react-router-dom';
import '../../company/catalog/catalog.scss';
import DeleteCatalogueDialog from './DeleteCatalogueDialog';
import EditTemplateDialog from './EditTemplateDialog';
function Catalog() {
  const [value, setValue] = React.useState('1');
  const [value1, setValue1] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange2 = (event, newValue) => {
    setValue1(newValue);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [anchorEpdf, setAnchorEpdf] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEpdf);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEpdf(event.currentTarget);
  };
  const handleClosepdf = (e) => {
    e.stopPropagation();
    setAnchorEpdf(null);
  };

  const [deleteCatalogue, setDeleteCatalogue] = useState(false);
  const closeDeleteCatalogueDialog = () => {
    setDeleteCatalogue(false);
  };

  const [editTemplate, setEditTemplate] = useState(false);
  const closeEditTemplateDialog = () => {
    setEditTemplate(false);
  };

  return (
    <div className="page-container top-space catalog-page">
      <div className="main-page-header ">
        <div className="page-breacrumb">
          <div className="breadcrumb-wrapper">
            <ul className="breadcrumb-links">
              <li>
                <a href="#">Company</a>
              </li>
              <li>Catalogue</li>
            </ul>
          </div>
          <RightPageHeader />
        </div>
        <div className="page-title d-flex justify-content-between">
          <h2>Catalogue</h2>
          <div className="page-filters-wrapper">
            <div className="right-filter">
              <div className="form-group sort-by">
                <span className="dropdown-text">Sort by</span>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                  }}
                  className="borderless-dropdown sm-height no-label"
                  defaultValue={'A-Z'}
                >
                  <MenuItem className="sorting-menu-item" value="A-Z">
                    A-Z
                  </MenuItem>
                  <MenuItem className="sorting-menu-item" value="Z-A">
                    Z-A
                  </MenuItem>
                  <MenuItem className="sorting-menu-item" value="Created on">
                    Created on
                  </MenuItem>
                </Select>
              </div>
              <FormControl variant="filled" className="search-input">
                {/* @ts-ignore */}
                <FilledInput
                  inputProps={{ 'data-testid': 'searchProjects' }}
                  type="text"
                  className="xs-input search-input-wrap"
                  placeholder="Search template"
                  startAdornment={
                    <InputAdornment position="start">
                      <em className="be-search"></em>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end" className="search-icon-wrap">
                      {/* {buildcardSearchText && (
                          <img
                            src={images.iconCrossCircle}
                            alt=""
                            onClick={clearInput}
                          />
                        )} */}
                    </InputAdornment>
                  }
                  // onChange={searchBuildcards}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>

      {/* <Box className="tab-contest" sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="All catalog " value="1" />
              <Tab label="Bases" value="2" />

              <Tab label="Journey" value="3" />
              <Tab label="Feature" value="4" />
            </TabList>
          </Box>
          <TabPanel className="catalog-wrapper" value="1">
            <ul className="filterList">
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Entrypass</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon1} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Building Permits</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon2} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Traffic Management</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon3} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Property Maintenance</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon4} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Property Registration</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon5} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Business Registration and Licensing</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon6} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Identity Management Module</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon7} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Payment Module</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </TabPanel>
          <TabPanel className="catalog-wrapper" value="2">
            <ul className="filterList">
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Entrypass</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon1} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Building Permits</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon2} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Traffic Management</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon3} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Property Maintenance</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon4} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Property Registration</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon5} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Business Registration and Licensing</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon6} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Identity Management Module</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="catalog-card" onClick={handleClickOpen}>
                  <div className="catalog-head">
                    <Button className="xs-btn is-icon-btn plus-icon">+</Button>
                    <div className="title">
                      <div className="brandicon">
                        <img src={images.catalogIcon7} alt="catalog icon" />
                      </div>
                      <span>SERVICE</span>
                      <h3>Payment Module</h3>
                    </div>
                  </div>
                  <div className="middle">
                    <p>Scan & measure objects</p>
                    <p>Data-driven recommendations</p>
                    <p>Virtually try-on items</p>
                  </div>
                  <div className="catalog-footer">
                    <div className="actionbx">
                      <span>TOTAL FEATURES</span>
                      <h5>49</h5>
                    </div>
                    <div className="actionbx">
                      <span>CREDITS</span>
                      <h5>120</h5>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </TabPanel>

          <TabPanel className="catalog-wrapper" value="3">
            Coming Soon
          </TabPanel>
          <TabPanel className="catalog-wrapper" value="4">
            Coming Soon
          </TabPanel>
        </TabContext>
      </Box> */}

      <ul className="filterList">
        <li>
          <div className="catalog-card" onClick={handleClickOpen}>
            <div>
              <div className="catalog-head">
                <div className="title">
                  <div className="brandicon">
                    <img src={images.catalogSw} alt="catalog icon" />
                  </div>
                  <span>Created by Ashesh Mishra</span>
                  <h3>Warehouse scanner 2.0</h3>
                </div>
                <Button
                  className="xs-btn is-icon-btn three"
                  id="basic-button"
                  variant="outlined"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e) => e?.stopPropagation()}
                >
                  <em
                    onClick={handleClick}
                    className="icon be-vertical-dots"
                    style={{ display: 'flex' }}
                  ></em>
                </Button>
                <Menu
                  className="three-dot-menu-paper"
                  id="basic-menu"
                  anchorEl={anchorEpdf}
                  open={open1}
                  onClose={handleClosepdf}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem
                    className="toggle-menu-item-row"
                    onClick={(e) => e?.stopPropagation()}
                  >
                    <em className="icon be-eye"></em> View details
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    // onClick={(ev) => ev.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditTemplate(true);
                    }}
                  >
                    <em className="icon be-edit"></em> Edit template
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    aria-label="archive-btn"
                    // onClick={(ev) => ev.stopPropagation()}

                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteCatalogue(true);
                    }}
                  >
                    <em className="icon ">
                      <img src={images.deleteIcon} alt="catalog icon" />
                    </em>
                    Delete
                  </MenuItem>
                </Menu>
              </div>
              <div className="middle">
                <p>
                  I did some more QA and the dropdown should also close when I
                  click on the entire button, and not only on the chevron icon
                  did some more QA and the dropdown.
                </p>
              </div>
            </div>
            <div className="catalog-footer">
              <div className="actionbx">
                <span>Journey</span>
                <h5>8</h5>
              </div>
              <div className="actionbx">
                <span>Features</span>
                <h5>46</h5>
              </div>
              <div className="actionbx">
                <span>CREDITS</span>
                <h5>120</h5>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="catalog-card" onClick={handleClickOpen}>
            <div>
              <div className="catalog-head">
                <div className="title">
                  <div className="brandicon">
                    <img src={images.catalogIcon} alt="catalog icon" />
                  </div>
                  <span>Created by Ashesh Mishra</span>
                  <h3>Warehouse scanner 2.0</h3>
                </div>
                <Button
                  className="xs-btn is-icon-btn three"
                  id="basic-button"
                  variant="outlined"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e) => e?.stopPropagation()}
                >
                  <em
                    onClick={handleClick}
                    className="icon be-vertical-dots"
                    style={{ display: 'flex' }}
                  ></em>
                </Button>
                <Menu
                  className="three-dot-menu-paper"
                  id="basic-menu"
                  anchorEl={anchorEpdf}
                  open={open1}
                  onClose={handleClosepdf}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem
                    className="toggle-menu-item-row"
                    onClick={(e) => e?.stopPropagation()}
                  >
                    <em className="icon be-eye"></em> View details
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    // onClick={(ev) => ev.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditTemplate(true);
                    }}
                  >
                    <em className="icon be-edit"></em> Edit template
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    aria-label="archive-btn"
                    // onClick={(ev) => ev.stopPropagation()}

                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteCatalogue(true);
                    }}
                  >
                    <em className="icon ">
                      <img src={images.deleteIcon} alt="catalog icon" />
                    </em>
                    Delete
                  </MenuItem>
                </Menu>
              </div>
              <div className="middle">
                <p>
                  I did some more QA and the dropdown should also close when I
                  click on the entire button, and not only on the chevron icon
                  did some more QA and the dropdown should also close when did
                  some more QA..
                </p>
              </div>
            </div>
            <div className="catalog-footer">
              <div className="actionbx">
                <span>Journey</span>
                <h5>8</h5>
              </div>
              <div className="actionbx">
                <span>Features</span>
                <h5>46</h5>
              </div>
              <div className="actionbx">
                <span>CREDITS</span>
                <h5>120</h5>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="catalog-card" onClick={handleClickOpen}>
            <div>
              <div className="catalog-head">
                <div className="title">
                  <div className="brandicon">
                    <img src={images.catalogIcon1} alt="catalog icon" />
                  </div>
                  <span>Created by Ashesh Mishra</span>
                  <h3>Building Permits Software solutions</h3>
                </div>
                <Button
                  className="xs-btn is-icon-btn three"
                  id="basic-button"
                  variant="outlined"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e) => e?.stopPropagation()}
                >
                  <em
                    onClick={handleClick}
                    className="icon be-vertical-dots"
                    style={{ display: 'flex' }}
                  ></em>
                </Button>
                <Menu
                  className="three-dot-menu-paper"
                  id="basic-menu"
                  anchorEl={anchorEpdf}
                  open={open1}
                  onClose={handleClosepdf}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem
                    className="toggle-menu-item-row"
                    onClick={(e) => e?.stopPropagation()}
                  >
                    <em className="icon be-eye"></em> View details
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    // onClick={(ev) => ev.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditTemplate(true);
                    }}
                  >
                    <em className="icon be-edit"></em> Edit template
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    aria-label="archive-btn"
                    // onClick={(ev) => ev.stopPropagation()}

                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteCatalogue(true);
                    }}
                  >
                    <em className="icon ">
                      <img src={images.deleteIcon} alt="catalog icon" />
                    </em>
                    Delete
                  </MenuItem>
                </Menu>
              </div>
              <div className="middle">
                <p>
                  Using this template you can create app to scan & measure
                  objects. Data-driven recommendations. Virtually try-on items.
                </p>
              </div>
            </div>
            <div className="catalog-footer">
              <div className="actionbx">
                <span>Journey</span>
                <h5>8</h5>
              </div>
              <div className="actionbx">
                <span>Features</span>
                <h5>46</h5>
              </div>
              <div className="actionbx">
                <span>CREDITS</span>
                <h5>120</h5>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="catalog-card" onClick={handleClickOpen}>
            <div>
              <div className="catalog-head">
                <div className="title">
                  <div className="brandicon">
                    <img src={images.catalogIcon2} alt="catalog icon" />
                  </div>
                  <span>Created by Ashesh Mishra</span>
                  <h3>Traffic Management App</h3>
                </div>
                <Button
                  className="xs-btn is-icon-btn three"
                  id="basic-button"
                  variant="outlined"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e) => e?.stopPropagation()}
                >
                  <em
                    onClick={handleClick}
                    className="icon be-vertical-dots"
                    style={{ display: 'flex' }}
                  ></em>
                </Button>
                <Menu
                  className="three-dot-menu-paper"
                  id="basic-menu"
                  anchorEl={anchorEpdf}
                  open={open1}
                  onClose={handleClosepdf}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem
                    className="toggle-menu-item-row"
                    onClick={(e) => e?.stopPropagation()}
                  >
                    <em className="icon be-eye"></em> View details
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    // onClick={(ev) => ev.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditTemplate(true);
                    }}
                  >
                    <em className="icon be-edit"></em> Edit template
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    aria-label="archive-btn"
                    // onClick={(ev) => ev.stopPropagation()}

                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteCatalogue(true);
                    }}
                  >
                    <em className="icon ">
                      <img src={images.deleteIcon} alt="catalog icon" />
                    </em>
                    Delete
                  </MenuItem>
                </Menu>
              </div>
              <div className="middle">
                <p>
                  I did some more QA and the dropdown should also close when I
                  click on the entire button, and not only on the chevron icon.
                </p>
              </div>
            </div>
            <div className="catalog-footer">
              <div className="actionbx">
                <span>Journey</span>
                <h5>8</h5>
              </div>
              <div className="actionbx">
                <span>Features</span>
                <h5>46</h5>
              </div>
              <div className="actionbx">
                <span>CREDITS</span>
                <h5>120</h5>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="catalog-card" onClick={handleClickOpen}>
            <div>
              <div className="catalog-head">
                <div className="title">
                  <div className="brandicon">
                    <img src={images.catalogSw} alt="catalog icon" />
                  </div>
                  <span>Created by Ashesh Mishra</span>
                  <h3>Warehouse scanner 2.0</h3>
                </div>
                <Button
                  className="xs-btn is-icon-btn three"
                  id="basic-button"
                  variant="outlined"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e) => e?.stopPropagation()}
                >
                  <em
                    onClick={handleClick}
                    className="icon be-vertical-dots"
                    style={{ display: 'flex' }}
                  ></em>
                </Button>
                <Menu
                  className="three-dot-menu-paper"
                  id="basic-menu"
                  anchorEl={anchorEpdf}
                  open={open1}
                  onClose={handleClosepdf}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem
                    className="toggle-menu-item-row"
                    onClick={(e) => e?.stopPropagation()}
                  >
                    <em className="icon be-eye"></em> View details
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    // onClick={(ev) => ev.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditTemplate(true);
                    }}
                  >
                    <em className="icon be-edit"></em> Edit template
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    aria-label="archive-btn"
                    // onClick={(ev) => ev.stopPropagation()}

                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteCatalogue(true);
                    }}
                  >
                    <em className="icon ">
                      <img src={images.deleteIcon} alt="catalog icon" />
                    </em>
                    Delete
                  </MenuItem>
                </Menu>
              </div>
              <div className="middle">
                <p>
                  I did some more QA and the dropdown should also close when I
                  click on the entire button, and not only on the chevron icon
                  did some more QA and the dropdown.
                </p>
              </div>
            </div>
            <div className="catalog-footer">
              <div className="actionbx">
                <span>Journey</span>
                <h5>8</h5>
              </div>
              <div className="actionbx">
                <span>Features</span>
                <h5>46</h5>
              </div>
              <div className="actionbx">
                <span>CREDITS</span>
                <h5>120</h5>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="catalog-card" onClick={handleClickOpen}>
            <div>
              <div className="catalog-head">
                <div className="title">
                  <div className="brandicon">
                    <img src={images.catalogIcon} alt="catalog icon" />
                  </div>
                  <span>Created by Ashesh Mishra</span>
                  <h3>Warehouse scanner 2.0</h3>
                </div>
                <Button
                  className="xs-btn is-icon-btn three"
                  id="basic-button"
                  variant="outlined"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e) => e?.stopPropagation()}
                >
                  <em
                    onClick={handleClick}
                    className="icon be-vertical-dots"
                    style={{ display: 'flex' }}
                  ></em>
                </Button>
                <Menu
                  className="three-dot-menu-paper"
                  id="basic-menu"
                  anchorEl={anchorEpdf}
                  open={open1}
                  onClose={handleClosepdf}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem
                    className="toggle-menu-item-row"
                    onClick={(e) => e?.stopPropagation()}
                  >
                    <em className="icon be-eye"></em> View details
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    // onClick={(ev) => ev.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditTemplate(true);
                    }}
                  >
                    <em className="icon be-edit"></em> Edit template
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    aria-label="archive-btn"
                    // onClick={(ev) => ev.stopPropagation()}

                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteCatalogue(true);
                    }}
                  >
                    <em className="icon ">
                      <img src={images.deleteIcon} alt="catalog icon" />
                    </em>
                    Delete
                  </MenuItem>
                </Menu>
              </div>
              <div className="middle">
                <p>
                  I did some more QA and the dropdown should also close when I
                  click on the entire button, and not only on the chevron icon
                  did some more QA and the dropdown should also close when did
                  some more QA..
                </p>
              </div>
            </div>
            <div className="catalog-footer">
              <div className="actionbx">
                <span>Journey</span>
                <h5>8</h5>
              </div>
              <div className="actionbx">
                <span>Features</span>
                <h5>46</h5>
              </div>
              <div className="actionbx">
                <span>CREDITS</span>
                <h5>120</h5>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="catalog-card" onClick={handleClickOpen}>
            <div>
              <div className="catalog-head">
                <div className="title">
                  <div className="brandicon">
                    <img src={images.catalogIcon1} alt="catalog icon" />
                  </div>
                  <span>Created by Ashesh Mishra</span>
                  <h3>Building Permits Software solutions</h3>
                </div>
                <Button
                  className="xs-btn is-icon-btn three"
                  id="basic-button"
                  variant="outlined"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e) => e?.stopPropagation()}
                >
                  <em
                    onClick={handleClick}
                    className="icon be-vertical-dots"
                    style={{ display: 'flex' }}
                  ></em>
                </Button>
                <Menu
                  className="three-dot-menu-paper"
                  id="basic-menu"
                  anchorEl={anchorEpdf}
                  open={open1}
                  onClose={handleClosepdf}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem
                    className="toggle-menu-item-row"
                    onClick={(e) => e?.stopPropagation()}
                  >
                    <em className="icon be-eye"></em> View details
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    // onClick={(ev) => ev.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditTemplate(true);
                    }}
                  >
                    <em className="icon be-edit"></em> Edit template
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    aria-label="archive-btn"
                    // onClick={(ev) => ev.stopPropagation()}

                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteCatalogue(true);
                    }}
                  >
                    <em className="icon ">
                      <img src={images.deleteIcon} alt="catalog icon" />
                    </em>
                    Delete
                  </MenuItem>
                </Menu>
              </div>
              <div className="middle">
                <p>
                  Using this template you can create app to scan & measure
                  objects. Data-driven recommendations. Virtually try-on items.
                </p>
              </div>
            </div>
            <div className="catalog-footer">
              <div className="actionbx">
                <span>Journey</span>
                <h5>8</h5>
              </div>
              <div className="actionbx">
                <span>Features</span>
                <h5>46</h5>
              </div>
              <div className="actionbx">
                <span>CREDITS</span>
                <h5>120</h5>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="catalog-card" onClick={handleClickOpen}>
            <div>
              <div className="catalog-head">
                <div className="title">
                  <div className="brandicon">
                    <img src={images.catalogIcon2} alt="catalog icon" />
                  </div>
                  <span>Created by Ashesh Mishra</span>
                  <h3>Traffic Management App</h3>
                </div>
                <Button
                  className="xs-btn is-icon-btn three"
                  id="basic-button"
                  variant="outlined"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e) => e?.stopPropagation()}
                >
                  <em
                    onClick={handleClick}
                    className="icon be-vertical-dots"
                    style={{ display: 'flex' }}
                  ></em>
                </Button>
                <Menu
                  className="three-dot-menu-paper"
                  id="basic-menu"
                  anchorEl={anchorEpdf}
                  open={open1}
                  onClose={handleClosepdf}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem
                    className="toggle-menu-item-row"
                    onClick={(e) => e?.stopPropagation()}
                  >
                    <em className="icon be-eye"></em> View details
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    // onClick={(ev) => ev.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditTemplate(true);
                    }}
                  >
                    <em className="icon be-edit"></em> Edit template
                  </MenuItem>
                  <MenuItem
                    className="toggle-menu-item-row"
                    aria-label="archive-btn"
                    // onClick={(ev) => ev.stopPropagation()}

                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteCatalogue(true);
                    }}
                  >
                    <em className="icon ">
                      <img src={images.deleteIcon} alt="catalog icon" />
                    </em>
                    Delete
                  </MenuItem>
                </Menu>
              </div>
              <div className="middle">
                <p>
                  I did some more QA and the dropdown should also close when I
                  click on the entire button, and not only on the chevron icon.
                </p>
              </div>
            </div>
            <div className="catalog-footer">
              <div className="actionbx">
                <span>Journey</span>
                <h5>8</h5>
              </div>
              <div className="actionbx">
                <span>Features</span>
                <h5>46</h5>
              </div>
              <div className="actionbx">
                <span>CREDITS</span>
                <h5>120</h5>
              </div>
            </div>
          </div>
        </li>
      </ul>
      {/* <div className="custom-pagination-wrapper">
        <Pagination count={20} shape="rounded" />
      </div> */}
      <React.Fragment>
        <Dialog
          className="catalog-detail-dialog"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="close-menu be-close" onClick={handleClose}></div>
          <DialogContent className="catalog-modal-content">
            <div className="catalog-content-left">
              <div className="titleRow">
                <div className="thumbIcon">
                  <img src={images.catalogSw} alt="catalog icon" />
                </div>
                <h2>
                  <span>Template</span>Warehouse scanner 1.0
                </h2>
              </div>
              <div className="cost-feature-row">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Ipsum has been the industry's standard
                  dummy text ever since the 1500s with a new template that
                  works.
                </p>
                <div className="catalog-modal-footer">
                  <div className="actionbx">
                    <span>Journeys</span>
                    <h5>5</h5>
                  </div>
                  <div className="actionbx">
                    <span>FEATURES</span>
                    <h5>49</h5>
                  </div>
                  <div className="actionbx">
                    <span>Credits</span>
                    <h5>800</h5>
                  </div>
                  <div className="actionbx">
                    <span>Created on</span>
                    <h5>Nov 11, 2024</h5>
                  </div>
                  <div className="actionbx">
                    <span>Publisehd by</span>
                    <h5>Ashesh Mishra</h5>
                  </div>
                </div>
              </div>

              <div className="projects-top-nav-wrapper">
                <Box
                  className="tab-contest"
                  sx={{ width: '100%', typography: 'body1' }}
                >
                  <TabContext value={value1}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange2}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Journey & features " value="1" />
                        <Tab label="Marketplace services" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel className="catalog-wrapper" value="1">
                      <div className="catalog-tab-content">
                        <div className="catalog-table">
                          <div className="catalog-table-head">
                            <div className="table-column">JOURNEY/FEATURE </div>
                            <div className="table-column">Credits </div>
                          </div>
                          <div className="table-bd">
                            <div className="table-row sel">
                              <div className="table-column-left">
                                <h3>Help users sign in</h3>
                              </div>
                              <div className="table-column-right">
                                <h3>200</h3>
                              </div>
                            </div>
                            <div className="table-row ">
                              <div className="table-column-left">
                                <p>Splash screen</p>
                              </div>
                              <div className="table-column-right">
                                <p> 100</p>
                              </div>
                            </div>

                            <div className="table-row">
                              <div className="table-column-left">
                                <p>Two factor authentication</p>
                              </div>
                              <div className="table-column-right">
                                <p>100</p>
                              </div>
                            </div>
                            <div className="table-row">
                              <div className="table-column-left">
                                <p>Two factor authentication</p>
                              </div>
                              <div className="table-column-right">
                                <p>100</p>
                              </div>
                            </div>
                            <div className="table-row">
                              <div className="table-column-left">
                                <p>Content Management</p>
                              </div>
                              <div className="table-column-right">
                                <p>100</p>
                              </div>
                            </div>
                          </div>
                          <div className="table-bd">
                            <div className="table-row sel">
                              <div className="table-column-left">
                                <h3>Locate areas of interest on map</h3>
                              </div>
                              <div className="table-column-right">
                                <h3>200</h3>
                              </div>
                            </div>
                            <div className="table-row">
                              <div className="table-column-left">
                                <p>Content Management</p>
                              </div>
                              <div className="table-column-right">
                                <p>100</p>
                              </div>
                            </div>

                            <div className="table-row">
                              <div className="table-column-left">
                                <p>Advanced search</p>
                              </div>
                              <div className="table-column-right">
                                <p>100</p>
                              </div>
                            </div>

                            <div className="table-row">
                              <div className="table-column-left">
                                <p>Two factor authentication</p>
                              </div>
                              <div className="table-column-right">
                                <p>100</p>
                              </div>
                            </div>
                          </div>
                          <div className="table-bd">
                            <div className="table-row sel">
                              <div className="table-column-left">
                                <h3>Locate areas of interest on map</h3>
                              </div>
                              <div className="table-column-right">
                                <h3>200</h3>
                              </div>
                            </div>
                            <div className="table-row">
                              <div className="table-column-left">
                                <p>Content Management</p>
                              </div>
                              <div className="table-column-right">
                                <p>100</p>
                              </div>
                            </div>

                            <div className="table-row">
                              <div className="table-column-left">
                                <p>Advanced search</p>
                              </div>
                              <div className="table-column-right">
                                <p>100</p>
                              </div>
                            </div>

                            <div className="table-row">
                              <div className="table-column-left">
                                <p>Two factor authentication</p>
                              </div>
                              <div className="table-column-right">
                                <p>100</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel className="catalog-wrapper" value="2">
                      <div className="catalog-tab-content">
                        <div className="catalog-table marketplace-table">
                          <div className="catalog-table-head">
                            <div className="table-column">Service </div>
                            <div className="table-column">Type </div>
                            <div className="table-column">Credits </div>
                          </div>
                          <div className="table-bd">
                            <div className="table-row ">
                              <div className="table-column-left">
                                <p className="d-flex align-items-center">
                                  <em className="img"></em> Paypal
                                </p>
                              </div>
                              <div className="table-column-right">
                                <p> Payment</p>
                              </div>
                              <div className="table-column-right">
                                <p> 100</p>
                              </div>
                            </div>
                            <div className="table-row ">
                              <div className="table-column-left">
                                <p className="d-flex align-items-center">
                                  <em className="img"></em> Shiprocket
                                </p>
                              </div>
                              <div className="table-column-right">
                                <p> Delivery</p>
                              </div>
                              <div className="table-column-right">
                                <p> 100</p>
                              </div>
                            </div>
                            <div className="table-row ">
                              <div className="table-column-left">
                                <p className="d-flex align-items-center">
                                  <em className="img"></em> Tucows
                                </p>
                              </div>
                              <div className="table-column-right">
                                <p> Domain</p>
                              </div>
                              <div className="table-column-right">
                                <p> 100</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
            <div className="catalog-content-right">
              <img src={(images as any).phoneImg} alt="phone" />
            </div>
          </DialogContent>
        </Dialog>
      </React.Fragment>

      <DeleteCatalogueDialog
        {...{ deleteCatalogue, closeDeleteCatalogueDialog }}
      />
      <EditTemplateDialog {...{ editTemplate, closeEditTemplateDialog }} />
    </div>
  );
}
export default Catalog;
