import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import images from '../../assets/images';
import instance from '../../shared/interceptor';
import { companyState, userState } from '../../shared/store/slices/userSlice';
import { COMPANY, ENTITY_TAGS } from '../../shared/util/constant';
import { debounce, globalError, setLoader } from '../../shared/util/util';
import './spaces.scss';
import OverflowTooltip from '../../shared/shared-comp/OverflowTooltip';

let prevCount = 0;

function TagResultSection({ showSearchTags, setShowSearchTags }) {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);

  const [searchTagList, setSearchTagList] = useState([]);
  const [page, setPage] = useState(1);
  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [entityTagMetaData, setEntityTagMetaData] = useState({
    next_page: null,
    per_page: null,
    total_count: null,
    total_pages: null,
  });
  const [entityList, setEntityList] = useState([]);
  const [entityType, setEntityType] = useState('all');
  const [searchTagVal, setSearchTagVal] = useState('');
  const [tagList, setTagList] = useState([]);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [hiddenTags, setHiddenTags] = useState([]);

  useEffect(() => {
    setSearchTagVal(showSearchTags);
    openSearchTag(showSearchTags);
  }, [showSearchTags]);

  const openSearchTag = (tag) => {
    addTagToSearchList(tag);
    document.body.classList.add('hide-scrollbar');
  };

  const handlePaginationChange = (e, val) => {
    setPage(val);
    searchEntityFromTags(entityType, searchTagList, val);
  };

  const handleClose = () => {
    setAnchorEl2(null);
    setAnchorEl3(null);
    setShowMenu(false);
    setHiddenTags([]);
  };

  const selectChangeHandler = (event) => {
    setEntityType(event.target.value);
    setPage(1);
    searchEntityFromTags(event.target.value, searchTagList, 1);
  };

  const tagSearchHandler = debounce((e) => {
    if (e?.target?.value) {
      getAllTags(e.target['value']);
      setAnchorEl2(e.target);
      setShowMenu(true);
    }
  });

  const handleRemoveSearchItem = (id) => {
    let itemIndex = searchTagList.map((item) => item.id).indexOf(id);
    let temp = JSON.parse(JSON.stringify(searchTagList));
    temp.splice(itemIndex, 1);
    setSearchTagList(temp);
    if (!temp?.length) setPage(1);
    searchEntityFromTags(entityType, temp, temp?.length >= 1 ? page : 1);
  };

  const addTagToSearchList = (tag) => {
    setSearchTagVal('');
    let foundIndex = searchTagList.map((item) => item.name).indexOf(tag?.name);
    if (foundIndex > -1) {
      return;
    }
    setSearchTagList((prev) => [...prev, tag]);
    searchEntityFromTags(entityType, [...searchTagList, tag], page);
  };

  const searchEntityFromTags = async (entity, list, p) => {
    try {
      if (prevCount < list.length && p > 1) {
        setPage(1);
        p = 1;
      }
      prevCount = list.length;
      setLoading(true);
      // setLoader(dispatch, true);
      let url = `${ENTITY_TAGS.replace(':id', compSel?.id)}?page=${p}`;
      if (entityType) {
        url += `&filter_type=${entity}`;
      }

      if (list.length > 0) {
        list.forEach((tag) => {
          url += `&tag_names[]=${encodeURIComponent(tag?.name)}`;
        });
      }

      let searchedEntititesRes = await instance.get(url);
      setEntityList(searchedEntititesRes?.['entity_tags'] ?? []);
      setEntityTagMetaData({
        next_page: searchedEntititesRes?.['pagination']?.['next_page'],
        per_page: searchedEntititesRes?.['pagination']?.['per_page'],
        total_count: searchedEntititesRes?.['pagination']?.['total_count'],
        total_pages: searchedEntititesRes?.['pagination']?.['total_pages'],
      });
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
      setLoading(false);
    }
  };

  const getAllTags = async (query?) => {
    try {
      let url = `${COMPANY}/${compSel?.id}/tags`;
      if (query) {
        url += `?q=${encodeURIComponent(query?.toLowerCase())}`;
      }
      let tagsRes = await instance.get(url);
      setTagList(tagsRes['tags']);
    } catch (e) {
      globalError(dispatch, e);
    }
  };

  const showRestTags = (e, tags) => {
    e.preventDefault();
    e.stopPropagation();
    if (tags.length > 2) {
      setHiddenTags(tags.slice(2));
    }
  };

  const handleMenuClick = (idx, e) => {
    setAnchorEl3({ [idx]: e.currentTarget });
  };

  const showingResult = [
    { value: 'all', label: 'All', disabled: false, icon: '' },
    {
      value: 'space',
      label: 'Spaces',
      disabled: false,
      icon: 'iconMultiUserXS',
    },
    // { value: 'project', label: 'Projects', disabled: false, icon: 'iconFolderXS' },
    {
      value: 'build_card',
      label: 'Buildcards',
      disabled: false,
      icon: 'iconBuildcardXS',
    },
    { value: 'goal', label: 'Goals', disabled: true, icon: 'iconFlagXS' },
    {
      value: 'meeting',
      label: 'Meetings',
      disabled: true,
      icon: 'iconMeetingsXS',
    },
    {
      value: 'whiteboard',
      label: 'Whiteboard',
      disabled: true,
      icon: 'iconWhiteboardXS',
    },
  ];

  const getIcon = (entity) => {
    if (entity?.entity_type == 'Space') {
      return images.iconMultiUser;
    } else if (entity?.entity_type == 'Project') {
      return images.iconFolder;
    } else if (entity?.entity_type == 'Whiteboard') {
      return images.iconWhiteboard;
    } else if (entity?.entity_type == 'BuildCard') {
      return images.iconBuildcard;
    }
  };

  //=====================================================

  return (
    <aside className="tag-result-wrapper" aria-label="search-sidebar">
      <div className="tag-result flexbox">
        <div className="content-row title-row">
          <div className="title-wrap">
            <div className="section-title">Space Tags</div>
            <Button
              data-testid="close-menu-btn"
              className="xs-btn purple-btn is-icon-btn closeButton"
              variant="contained"
              onClick={() => {
                setShowSearchTags(null);
                document.body.classList.remove('hide-scrollbar');
              }}
            >
              <span className="modal-close be-close"></span>
            </Button>
          </div>
          <div className="tags">
            {/* This should be the selected one and should add to the searched list */}
            <Stack direction="row" className="purple-chips" spacing={1}>
              {searchTagList?.map((item) => {
                return (
                  <Chip
                    data-testid="delete-chip-sidebar"
                    key={item?.id}
                    label={item?.name}
                    onDelete={() => handleRemoveSearchItem(item?.id)}
                    deleteIcon={<span className="icon be-close-dark"></span>}
                  />
                );
              })}
              <div className="form-group search-tag-container">
                <FormControl variant="filled">
                  {/* @ts-ignore */}
                  <FilledInput
                    inputProps={{ 'data-testid': 'search-for-tags' }}
                    type="text"
                    value={searchTagVal}
                    className="xs-input"
                    placeholder="Search for tag"
                    onChange={(e) => {
                      setSearchTagVal(e.target['value']);
                      tagSearchHandler(e);
                    }}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                  />
                  {tagList?.filter(
                    (tag) =>
                      !searchTagList.some(
                        (existingTag) => existingTag?.name === tag?.name
                      )
                  )?.length > 0 && (
                    <Menu
                      anchorEl={anchorEl2}
                      open={showMenu}
                      onClose={handleClose}
                      className="header-search-tag-dropdown-menu"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <div className="dropdown-wrapper">
                        <Stack direction="row" spacing={1}>
                          {tagList
                            ?.filter(
                              (tag) =>
                                !searchTagList.some(
                                  (existingTag) =>
                                    existingTag?.name === tag?.name
                                )
                            )
                            ?.map((tag) => {
                              return (
                                <div key={tag?.id} className="list-item-row">
                                  <Chip
                                    key={tag?.id}
                                    data-testid="add-to-search"
                                    label={tag?.name}
                                    onClick={() => addTagToSearchList(tag)}
                                    deleteIcon={
                                      <span className="icon be-close-dark"></span>
                                    }
                                  />
                                </div>
                              );
                            })}
                        </Stack>
                      </div>
                    </Menu>
                  )}
                </FormControl>
              </div>
            </Stack>
          </div>

          <div className="result-count-wrap">
            <span>
              Showing <span>{entityList.length > 0 ? 1 : 0}</span>-
              <span>{entityList?.length >= 15 ? 15 : entityList?.length}</span>{' '}
              of
              <span> {entityTagMetaData?.total_count}</span> results
            </span>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={entityType}
              label="Spaces"
              onChange={selectChangeHandler}
            >
              {showingResult.map((item, idx) => {
                return (
                  <MenuItem
                    sx={{ width: '220px' }}
                    key={item?.label}
                    disabled={item?.disabled}
                    value={item?.value}
                  >
                    <img src={images[item.icon]} alt="" />
                    &nbsp;&nbsp;{item?.label}
                  </MenuItem>
                );
              })}
            </Select>
            {/* iconMultiUserXS, iconFolderXS, iconBuildcardXS, iconFlagXS, iconMeetingsXS, iconWhiteboardXS */}
          </div>
        </div>
        <div className="content-section scrollable">
          {loading && (
            <div>
              <img
                src={(images as any).buAnim}
                alt="B"
                style={{
                  zIndex: 9999,
                  position: 'fixed',
                  top: '50%',
                  left: '82%',
                  height: '80px',
                  width: '80px',
                  transform: 'translate(-50%, -82%)',
                }}
              />
            </div>
          )}
          {entityList?.map((entity, idx) => {
            return (
              <div className="content-row" key={`${entity?.entity_id} ${idx}`}>
                <div className="thumb-wrap user-link">
                  <span className="user-avatar">
                    <img src={getIcon(entity)} alt="entity icon" />
                  </span>
                </div>
                <div className="right-content">
                  <div className="category-name">{entity?.entity_type}</div>
                  <div className="title">{entity?.entity_name}</div>
                  <div className="chips">
                    <Stack direction="row" spacing={1}>
                      {entity?.tags.slice(0, 2).map((tag) => {
                        return (
                          <Chip
                            label={
                              <OverflowTooltip
                                text={tag?.name}
                                tooltip={tag?.name}
                                style={{ marginTop: '5px' }}
                              />
                            }
                            key={tag?.id}
                          />
                        );
                      })}
                      {entity?.tags.length > 2 && (
                        <Chip
                          onClick={(e) => {
                            handleMenuClick(idx, e);
                            showRestTags(e, entity?.tags);
                          }}
                          label={`+${entity?.tags.length - 2}`}
                        />
                      )}
                    </Stack>
                    <Menu
                      className="tags-menu"
                      anchorEl={anchorEl3?.[idx]}
                      open={Boolean(anchorEl3?.[idx])}
                      onClose={handleClose}
                    >
                      {hiddenTags.map((item) => (
                        <MenuItem key={item?.id}>
                          <span className="list-item">{item?.name}</span>
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {entityTagMetaData?.total_pages > 1 && (
          <div className="custom-pagination-wrapper">
            <Pagination
              data-testid="change-handle"
              page={page}
              count={entityTagMetaData?.total_pages}
              shape="rounded"
              size="large"
              siblingCount={2}
              onChange={handlePaginationChange}
            />
          </div>
        )}
      </div>
    </aside>
  );
}

export default TagResultSection;
